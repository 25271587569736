<template>
  <div>
    <Card body-classes="user-tables">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between">
          <span>Пользователи</span>

          <div class="mb-0 w-65">
            <JsonExcel
              class="btn btn-primary"
              :fetch="fetchData"
              :fields="json_fields"
              type="csv"
              name="пользователи.xls"
            >
              Скачать
              <i class="bi bi-download" />
            </JsonExcel>
          </div>

          <Input
            v-model="dataTableSearch"
            size="sm"
            placeholder="Поиск"
            class="mb-0 w-25"
          />
        </div>
      </template>
      <div>
        <DataTable
          class="table-bordered"
          show-filter-buttons
          :data="dataTable"
          :headers="dataTableHeaders"
          :search-keyword="dataTableSearch"
          :per-page="dataTablePerPage"
          :current-page="dataTablePage"
        >
          <template v-slot:header="{sortAsc, sortDesc, sortNone, sortedHeaders}">
            <Cell  
              v-for="(header, hKey) in dataTableHeaders"
              :key="hKey"
              is-header
            >
              <div
                v-if="header !== null && header.toString().toLowerCase() !== 'select'"
                class="d-flex flex-row justify-content-left"
              >
                <div>
                  {{ header }}
                </div>
                <div
                  class="d-flex justify-content-left align-items-center"
                >
                  <div class="mx-1">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      :class="['sort-icon', {'active':sortedHeaders[header] === 'asc'}]"
                      @click="sortAsc(header)"
                    ><path
                      fill="currentColor"
                      d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"
                    /></svg>
                  </div>
                  <div>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      :class="['sort-icon', {'active':sortedHeaders[header] === 'desc'}]"
                      @click="sortDesc(header)"
                    ><path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    /></svg>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="d-flex justify-content-left align-items-center"
                >
                  <Checkbox
                    v-model="selectAll"
                  />
                </div>
              </div>
            </Cell>
          </template>
          <template v-slot:default="{ row, rowKey, rowKeyViewport }">
            <Cell
              v-for="(cellData, key) in row"
              :key="key"
              :cell-classes="['d-flex justify-content-center']"
              :class="[{ 'bg-gray-200': dataTable[rowKey].Selected === true }]"
            >
              <Checkbox
                v-if="key.toString().toLowerCase() === 'selected'"
                v-model="dataTable[rowKey].Selected"
              />
              <button 
                v-else-if="key.toString().toLowerCase() === 'delete'"
                class="btn btn-outline-light-bg-danger mx-2 mt-3"
                @click="deleteUserDialog(dataTable[rowKey].delete)"
              >
                Удалить
              </button>
              <span v-else>{{ cellData }}</span>
            </Cell>
          </template>
        </DataTable>
      </div>
      <div
        class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-end"
      >
        <div class="me-sm-4 mb-sm-0 mb-3">
          <p class="mb-2 small">
            Пользователей на странице
          </p>
          <Select
            :data="[5, 10, 15, 25, 100]"
            :selected="dataTablePerPage"
            @change="(dataTablePerPage = $event), (dataTablePage = 1)"
          />
        </div>
        <div>
          <Pagination
            v-model="dataTablePage"
            :pages="Math.ceil(dataTable.length / dataTablePerPage)"
          >
            <template v-slot:next>
              Дальше
            </template>
            <template v-slot:previous>
              Предыдущая
            </template>
          </Pagination>
        </div>
      </div>
    </Card>

    <Modal
      :open="deleteUserModal"
      @closed="deleteUserModal = false"
    >
      <div class="modal-header">
        <h5 class="modal-title m-0">
          Удаление пользователя
        </h5>
        <button
          type="button"
          class="btn-close outline-none"
          data-dismiss="modal"
          aria-label="Close"
          @click="deleteUserModal = false"
        />
      </div>
      <div class="modal-body">
        <p>Вы действительно хотите удалить пользователя?</p>
      </div>
      <div class="modal-footer border-0">
        <button
          type="button"
          class="btn btn-success"
          @click="deleteUser()"
        >
          Да
        </button>
        <button
          type="button"
          class="btn btn-danger"
          data-dismiss="modal"
          @click="deleteUserModal = false"
        >
          Отмена
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import UserService from "../../services/UserService";
import CourseService from "../../services/CourseService";
import moment from "moment-timezone";
import swal from 'sweetalert';
 
export default {
  name: "UserTables",
  data() {
    return {
      dataTableHeaders: [
        'Select',
        "Telegram UID",
        "Ник",
        "Имя",
        "Пройдено курсов",
        "Пройдено дней",
        "Накопленные очки",
        "Действия"
      ],
      dataTable: [],
      dataTableSearch: "",
      dataTablePage: 1,
      dataTablePerPage: 10,
      json_fields: {
        'Telegram UID': 'tg_uid',
        'Ник': 'username',
        'Имя': 'fio',
      },
      usersData: [],
      selectAll: false,
      deletingUserId: null,
      deleteUserModal: false,
    };
  },

  watch: {
    selectAll: function(select) {
      const vm = this;
      for (const userTable of vm.dataTable) {
        userTable.Selected = select;
      }
    }
  },

  async mounted() {
    const self = this;
    let data = await UserService.getAll();
    self.usersData = data;
    
    data.forEach((user, index) => {
      this.$set(this.dataTable, index, {
        Selected: false,
        tg_uid: user.tg_uid,
        username: user.username ? '@' + user.username : null,
        fio: user.fio,
        num_courses: this.getNumCompletedCourse(user.courses),
        num_days: this.getNumCompletedDays(user.courses),
        num_points: this.getNumPoints(user.courses),
        delete: user._id
      });
    });

    const courses = await CourseService.getAll('_id, name');
    for (const course of courses) {
      self.json_fields[course.name] = {
        field: 'course_' + course._id,
        callback: (course_data) => {
          let result = '';

          if (course_data) {
            result = `Cтатус: ${course_data.status}\n\
Пройдено дней: ${course_data.completed_days_num}\n\
Накоплено очков: ${course_data.points}`;
            if (course_data.cert_completed_date) {
              result += `\nДата выдачи сертификата: ${course_data.cert_completed_date}\n\
Номер сертификата: ${course_data.serial_number}`;
            }
          }

          return result;
        }
      }
    }
  },

  methods: {
    getNumCompletedCourse(courses) {
      let num_courses = 0;

      for (const course of courses) {
        if (course.isActive == false) {
          num_courses += 1;
        }
      }

      return num_courses;
    },
    getNumCompletedDays(courses) {
      let num_days = 0;

      for (const course of courses) {
        for (const day of course.days) {
          if (day.isActive == false) {
            num_days += 1;
          }
        }
      }

      return num_days;
    },
    getNumPoints(courses) {
      let points = 0;

      for (const course of courses) {
        points += course.points;
      }

      return points;
    },
    async fetchData() {
      const self = this;
      const users_json = [];

      for (const userTable of self.dataTable) {
        if (userTable.Selected) {
          const userData = self.usersData.find((user) => user.tg_uid === userTable.tg_uid);
            const userJson = {
            tg_uid: userData.tg_uid,
            username: userData.username ? '@' + userData.username : null,
            fio: userData.fio
          };

          for (const course of userData.courses) {
            let completedDayNums = 0;
            let completedDate = null;
            let serialNumber = null;
            
            for (const day of course.days) {
              if (day.isActive === false) {completedDayNums += 1;}
            }

            const cert = userData.certificates.find((certificate) => certificate.course_id == course.course_id);
            if (cert) {
              completedDate = moment(cert.created_at).tz("Europe/Moscow").format('DD.MM.Y').toString();
              serialNumber = cert.serialNumber;
            }

            userJson['course_' + course.course_id] = {
              status: course.isActive ? 'В процессе' : 'Пройден',
              completed_days_num: completedDayNums,
              points: course.points,
              cert_completed_date: completedDate,
              serial_number: '№' + serialNumber,
            };
          }
          
          users_json.push(userJson);
        }
      }

      if (users_json.length === 0) {
        swal("Внимание!", "Выберите хотя бы 1 пользователя", "warning");
        return;
      }

      return users_json;
    },
    deleteUserDialog(user_id) {
      this.deletingUserId = user_id;
      this.deleteUserModal = true;
    },

    deleteUser() {
      const vm = this;
      this.deleteUserModal = false;

      UserService.delete(this.deletingUserId).then(response => {
        vm.dataTable = vm.dataTable.filter((item) => {
          return item.delete !== vm.deletingUserId;
        });
        vm.deletingUserId = null;
        vm.$forceUpdate();
        swal("Успех!", "Пользователь удален", "success");
      }).catch(error => {
        console.log(error);
        let err_message = "Что то пошло не так";
        if (error.response && error.response.data.message) {
            err_message = error.response.data.message;
        }
        swal("Ошибка!", err_message, "error");
      });
    },
  },
};
</script>

<style lang="scss">
div.card-body.user-tables th.datatable-cell div.datable-cell-content div {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
</style>